import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './scss/main.scss';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Wedding from './pages/Wedding';


function App(){

  return (
    
    <BrowserRouter>
      <Navbar />
      <Route path="/" exact component={Home}/>
      <Route path="/wedding" exact component={Wedding}/>
    </BrowserRouter>

  );
  
}

export default App;
