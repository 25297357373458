import React, { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { photos } from './Photos';
import styled from 'styled-components';
import GalleryImg from './../img/galleryTitle.png';
import FilterSelectedImg from './../img/active-filter.png';

const GalleryTitle = styled.div`
  display: flex;
  justify-content: center;
  max-width: 175px;
  margin: 100px auto 50px auto;
`;

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 50px;
`;

const Filter = styled.button`
  padding: 5px 20px;
  background: white;
  font-family: 'Montserrat';
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: white;
  border: 0;
  margin: 15px 10px;
  outline: none;

  &.active,
  &:hover {
    position: relative;
    transition: 0.25s;

    ::after {
      content: '';
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      background: url(${FilterSelectedImg}) no-repeat;
      width: 101px;
      height: 21px;
      margin: 0 auto 5px;
    }
  }
`;

const WeddingGallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState('');

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  /* 
  const removeElement = (arr) => {
    let what, a = arr, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
  }
  */

  const addFilter = (filter) => {
    const filtersCopy = [];
    if (!filters.includes(filter)) {
      filtersCopy.push(filter);
    }
    setFilters(filtersCopy);
  };

  let filteredPhotos = [...photos];
  if (search !== '') {
    filteredPhotos = filteredPhotos.filter((photo, index) => {
      const match = [];
      if (photo.persons && photo.persons.length > 0) {
        photo.persons.map((person) => {
          if (person.toLowerCase().includes(search)) {
            return match.push(person);
          }
          return false;
        });
      }
      if (match.length > 0) {
        return true;
      }
      return false;
    });
  }

  /*
  const applyFilter = ( filters, posts, field, arraysComparison = true ) =>{
    posts= posts.filter( (post) =>{
      const match = [];
        if (arraysComparison){
          filters.forEach( 
            (filter) => post.node.frontmatter[field].forEach( (postField)=>
              {
                  if (filter === postField){
                    match.push(filter);
                  }
              }
            )
          )
        }
        else{
          filters.forEach( 
            (filter) => 
              {
                if (filter === post.node.frontmatter[field]) { 
                  match.push(filter);
                } 
              }
          )
        }

        if (match.length > 0) {
          return true;
        }
        return false;
    })
    return posts;
  }
  */

  if (filters.length > 0) {
    filteredPhotos = filteredPhotos.filter((photo) => {
      const match = [];
      filters.forEach((filter) => {
        if (photo.categories && photo.categories.length > 0) {
          photo.categories.forEach((category) => {
            if (filter === category) {
              match.push(filter);
            }
          });
        }
      });
      if (match.length > 0) {
        return true;
      }
      return false;
    });
  }

  return (
    <div>
      <GalleryTitle>
        <img src={GalleryImg} alt="Gallery"></img>
      </GalleryTitle>
      <div className="c-search">
        <input
          id="gallery-search"
          type="text"
          name="search"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Escreve o teu nome..."
        />
        <label for="gallery-search">Beta!</label>
      </div>
      <Filters>
        <Filter onClick={() => setFilters([])} className={filters.length === 0 ? 'active' : null}>
          Tudo
        </Filter>
        <Filter
          onClick={() => addFilter('casa-noivo')}
          className={filters.includes('casa-noivo') ? 'active' : null}
        >
          Noivo
        </Filter>
        <Filter
          onClick={() => addFilter('casa-noiva')}
          className={filters.includes('casa-noiva') ? 'active' : null}
        >
          Noiva
        </Filter>
        <Filter
          onClick={() => addFilter('igreja')}
          className={filters.includes('igreja') ? 'active' : null}
        >
          Igreja
        </Filter>
        <Filter
          onClick={() => addFilter('grupo')}
          className={filters.includes('grupo') ? 'active' : null}
        >
          Grupo
        </Filter>
        <Filter
          onClick={() => addFilter('quinta')}
          className={filters.includes('quinta') ? 'active' : null}
        >
          Quinta
        </Filter>
        <Filter
          onClick={() => addFilter('corte-bolo')}
          className={filters.includes('corte-bolo') ? 'active' : null}
        >
          Corte do Bolo
        </Filter>
        <Filter
          onClick={() => addFilter('decoração')}
          className={filters.includes('decoração') ? 'active' : null}
        >
          Decoração
        </Filter>
      </Filters>
      <Gallery photos={filteredPhotos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={filteredPhotos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};
export default WeddingGallery;
