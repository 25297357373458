import React from 'react';
import HomeMainImage from './../img/casa-de-ferreiro.png';

const Home = () => (
  <div className="c-container">
    <section className="c-hero">
      <div className="c-hero__wrapper">
        <img src={HomeMainImage} className="c-hero__img" alt="Casa de Ferreiro, Espeto de Pau" />
      </div>
    </section>
  </div>
);

export default Home;
