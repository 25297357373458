import React from 'react';
import Logo from './../img/logo.svg';
import { Link } from 'react-router-dom';

const Navbar = () => (
  <div className="c-container">
    <nav className="c-nav">
      <li className="c-nav__item  c-nav__item--disabled">About</li>
      <li className="c-nav__item  c-nav__item--disabled">Work</li>
      <li className="c-nav__item ">
        <Link to="/">
          <img src={Logo} className="c-nav__logo" alt="logo" />
        </Link>
      </li>
      <li class="c-nav__item">
        <Link to="/wedding">Wedding</Link>
      </li>
      <li class="c-nav__item  c-nav__item--disabled">Contacts</li>
    </nav>
  </div>
);

export default Navbar;
