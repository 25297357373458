import React from 'react';
import Title from './../img/wedding.svg';
import Church from './../img/church.svg';
import Venue from './../img/venue.svg';
import WeddingGallery from './../components/WeddingGallery';

const Wedding = () => (
  <div class="c-container">
    <section class="c-banner">
      <div class="c-banner__wrapper">
        <img src={Title} class="c-banner__img" alt="Cátia & Norberto" />
      </div>
    </section>

    <section class="c-options">
      <div class="c-options__wrapper">
        <div class="c-options__option">
          <div class="c-options__title">
            <img src={Church} alt="Capela" />
          </div>
          <div class="c-options__links">
            <a
              href="http://catiacosta.pt/wedding/church-book.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Livro da missa
            </a>

            <a
              href="http://maps.google.com/?q=Capela de São Miguel coimbra"
              target="_blank"
              rel="noopener noreferrer"
            >
              Localização
            </a>
          </div>
        </div>
        <div class="c-options__option">
          <div class="c-options__title">
            <img src={Venue} alt="Quinta" />
          </div>
          <div class="c-options__links">
            <a
              href="http://catiacosta.pt/wedding/sitting-plan.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sitting Plan
            </a>

            <a
              href="http://catiacosta.pt/wedding/menu.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ementa
            </a>

            <a
              href="http://maps.google.com/?q=Quinta do Sobreiro Lamarosa"
              target="_blank"
              rel="noopener noreferrer"
            >
              Localização
            </a>
          </div>
        </div>
      </div>
    </section>

    <WeddingGallery />
  </div>
);

export default Wedding;
